<template>
  <Menubar :model="items">
    <template #start>
      <img
        alt="logo"
        @click="$router.push('/admin/')"
        src="../assets/logo.png"
        height="40"
        class="p-mr-2"
      />
    </template>
    <template #end>
      <!-- <i
        class="pi pi-bell p-text-secondary"
        style="font-size: 2rem;margin-left: 8px;"
        v-badge="countNotifications"
        @click="toggleNotfy"
        aria:haspopup="true"
        aria-controls="overlay_panel"
      ></i>

      <OverlayPanel
        ref="op"
        appendTo="body"
        :showCloseIcon="true"
        id="overlay_panel"
        style="width: 450px"
      >
        <DataTable :value="notifications" :paginator="true" :rows="5">
          <Column>
            <template #body="slotProps">
              <p
                @click="
                  openNotification(slotProps.data.id, slotProps.data.tableName)
                "
              >
                {{ slotProps.data.name }} #{{ slotProps.data.tableName }}
              </p>
            </template>
          </Column>
        </DataTable>
      </OverlayPanel> -->

      <Button
        :label="userName"
        @click="toggle"
        aria-haspopup="true"
        aria-controls="overlay_tmenu"
        class="p-button-default"
      />
      <TieredMenu
        id="overlay_tmenu"
        ref="menu"
        :model="itemsList"
        :popup="true"
      />
    </template>
  </Menubar>
</template>

<script>
import Menubar from "primevue/menubar";
export default {
  components: {
    Menubar,
  },
  name: "app-header",
  data() {
    const user = JSON.parse(localStorage.adminKB);
    const xcount = this.countWords(user.fullName);
    let userName = user.fullName;
    if (xcount > 1) {
      userName = userName.split(" ")[0];
    }
    return {
      userName,
      items: [
        {
          label: "Hauptsächlich",
          icon: "pi pi-fw pi-home",
          to: "/admin",
        },
      ],
      itemsList: [
        {
          label: "Profil",
          icon: "pi pi-fw pi-user",
          to: "/admin/users/edit/" + user.id,
        },
        {
          separator: true,
        },
        {
          label: "Abmelden",
          icon: "pi pi-fw pi-power-off",
          command: () => {
            this.logout();
          },
        },
      ],
      list: [],
      notifications: [],
      user,
      countNotifications: 0,
    };
  },
  methods: {
    countWords(str) {
      return str.trim().split(/\s+/).length;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },

    logout() {
      delete localStorage.adminKB;
      location.reload();
    },
  },
  async created() {
    // this.items = [
    //   {
    //     label: 'Angestellte',
    //     icon: 'pi pi-fw pi-users',
    //     to: '/admin/users',
    //   },

    //   {
    //     label: 'Massage',
    //     icon: 'pi pi-fw pi-users',
    //     to: '/admin/massage',
    //   },

    //   {
    //     label: 'Massage bestellen',
    //     icon: 'pi pi-fw pi-users',
    //     to: '/admin/massageorder',
    //   },

    //   {
    //     label: 'Geschenke',
    //     icon: 'pi pi-fw pi-users',
    //     to: '/admin/gifts',
    //   },

    //   {
    //     label: 'Pakete',
    //     icon: 'pi pi-fw pi-users',
    //     to: '/admin/packages',
    //   },

    //   {
    //     label: 'Pakete Benutzer',
    //     icon: 'pi pi-fw pi-users',
    //     to: '/admin/packagesuser',
    //   },
    //   {
    //     label: 'Blog',
    //     icon: 'pi pi-fw pi-users',
    //     to: '/admin/blog',
    //   },
    //   {
    //     label: 'Kontaktiere uns',
    //     icon: 'pi pi-fw pi-users',
    //     to: '/admin/contact',
    //   },
    // ];

    const dcount = await this.$http.post(`contactUs/count`, {
      done: 0,
    });

    const gcount = await this.$http.post(`gifts/count`, {
      ended: 0,
    });

    const admin = JSON.parse(localStorage.adminKB);
    if (admin.role == "admin") {
      this.items = [
        {
          label: "Angestellte",
          icon: "pi pi-fw pi-users",
          to: "/admin/users",
        },
        {
          label: "Kunden",
          icon: "pi pi-fw pi-sitemap",
          to: "/admin/clients",
        },
        {
          label: "Massage",
          icon: "pi pi-fw pi-server",
          // to: '/admin/massage',
          items: [
            {
              label: "Sections",
              icon: "pi pi-fw pi-th-large",
              to: "/admin/sections",
            },
            {
              label: "Massage",
              icon: "pi pi-fw pi-server",
              to: "/admin/massage",
            },
          ],
        },

        {
          label: "Massage bestellen",
          icon: "pi pi-fw pi-shopping-bag",
          to: "/admin/massageorder",
        },

        {
          label: "Gutschein " + gcount.data,
          icon: "pi pi-fw pi-gift",
          to: "/admin/gifts",
        },

        {
          label: "Pakete",
          icon: "pi pi-fw pi-box",
          to: "/admin/packages",
        },

        {
          label: "Pakete Benutzer",
          icon: "pi pi-fw pi-flag-fill",
          to: "/admin/packagesuser",
        },
        {
          label: "Produktion",
          icon: "pi pi-fw pi-chart-line",
          items: [
            {
              label: "Produktvertreiber",
              icon: "pi pi-fw pi-th-large",
              to: "/admin/website_producat",
            },
            // {
            //   label: "Produktkategorien",
            //   icon: "pi pi-fw pi-sliders-v",
            //   to: "/admin/cat_producat",
            // },
            {
              label: "Produktion",
              icon: "pi pi-fw pi-th-large",
              to: "/admin/blog",
            },
            // {
            //   label: "Orders",
            //   icon: "pi pi-fw pi-cart-plus",
            //   to: "/admin/orders",
            // },
            {
              label: "Purchasing",
              icon: "pi pi-fw pi-download",
              to: "/admin/purchasing",
            },
            {
              label: "Seling",
              icon: "pi pi-fw pi-upload",
              to: "/admin/carts",
            },
            
          ],
        },

        {
          label: "Support-Tickets",
          icon: "pi pi-fw pi-ticket",
          to: "/admin/supportTickets",
        },
        {
          label: "Kontaktiere uns " + dcount.data,
          icon: "pi pi-fw pi-chart-bar",
          to: "/admin/contact",
        },
        {
          label: "Reports",
          icon: "pi pi-fw pi-users",
          items: [
            {
              label: "Report",
              icon: "pi pi-fw pi-users",
              to: "/admin/reports/index",
            },
            // {
            //   label: "By Massage",
            //   icon: "pi pi-fw pi-users",
            //   to: "/admin/reports/massageorder",
            // },
            // {
            //   label: "By Massager",
            //   icon: "pi pi-fw pi-users",
            //   to: "/admin/reports/user",
            // },
            {
              label: "Edit Home",
              icon: "pi pi-fw pi-users",
              to: "/admin/reports/editHome",
            },
            // cartsUsers
            {
              label: "Admins Reports ",
              icon: "pi pi-fw pi-users",
              to: "/admin/reports/cartsUsers",
            },
            {
              label: 'تقرير المبيعات الإجمالية حسب المنتج',
              icon: "pi pi-fw pi-users",
              to: "/admin/reports/carts_items",
            }
          ],
        },
      ];
    } else {
      this.items = [
        {
          label: "Massage bestellen",
          icon: "pi pi-fw pi-users",
          to: "/admin/massageorder",
        },
      ];
    }
  },
};
</script>